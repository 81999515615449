import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';

export type MainScreen5Props = {};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 62rem;
  margin-bottom: 336rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-top: 0;
    margin-bottom: 52rem;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }

  & > img {
    width: 535rem;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      order: 1;
      width: 100%;
      margin-left: 0 !important;
      margin-top: 54rem;
    }
  }
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 44rem;
  line-height: 120%;
  margin-bottom: 89rem;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
    text-align: center;
    margin-bottom: 42rem;
    box-sizing: border-box;
    padding-left: 16rem;
    padding-right: 16rem;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 57rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    gap: 48rem;
  }
`;
const Card = styled.div`
  width: 446rem;
  font-weight: 300;
  font-size: 22rem;
  line-height: 140%;
  padding: 24rem;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 16rem;
    line-height: 140%;
    padding: 13rem 20rem;
    margin-left: 10rem;
    margin-right: 10rem;
    width: 304rem;
  }

  &:before {
    content: '';
    background: #FFFFFF;
    box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
    display: block;
    width: 100%;
    height: 100%;
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 24rem;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    z-index: -3;
    box-sizing: border-box;
    border: 3rem solid #DCDCFF;
    top: 18rem;
    left: 21rem;
    position: absolute;
    border-radius: 24rem;

    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      border: 2rem solid #DCDCFF;
      top: 10rem;
      left: 10rem;
    }
  }

`;

export const MainScreen5: React.FC<MainScreen5Props> = () => (
  <Wrapper>
    <Title>Не уверены, что справитесь сами?</Title>
    <ContainerLocal>
      <img
        src={'/static/screen-5/man-with-guant-phone.svg'}
        alt={'Мужик с большим телефоном'}
        style={{ marginLeft: '-127rem' }}
      />
      <Right>
        <Card>
          Мастера нашей платформы поддержат в формате видеочата со специалистом
        </Card>
        <Card>
          С нами вы станете экспертом по своим устройствам в удобное для вас
          время и в любом месте
        </Card>
      </Right>
    </ContainerLocal>
  </Wrapper>
);
