import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';

const Wrapper = styled.div`
  margin-bottom: 330rem;
  position: relative;
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;

  img {
    width: 499rem;
    flex-shrink: 0;
    margin-right: -64rem;
    max-width: none;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Text1 = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }

`;
const Text2 = styled.div`
  max-width: 612rem;
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 90rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
    margin-bottom: 39rem;
  }
`;
const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 20rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;
export const MainScreen6: React.FC = () => (
  <Wrapper>
    <ContainerLocal>
      <Left>
        <Text1>
          МайТех - это удобно
        </Text1>
        <Divider/>
        <Text2>
          Инструкции, чеки и гарантийные талоны — вся необходимая информация о
          вашей технике всегда под рукой
        </Text2>
      </Left>
      <Right>
        <img
          src={'/static/screen-6/adventages.png'}
          alt={'Телефон с приложение mytech'}
        />
      </Right>
    </ContainerLocal>
  </Wrapper>
);
