import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';

export type MainScreen4CardProps = {
  icon: React.ReactNode;
  title: string;
  caption: string;
};

const Wrapper = styled.div`
  padding: 20rem 24rem;
  background: #fff;
  box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
  border-radius: 24rem;
  display: flex;
  gap: 24rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    transform: scale(0.5);
    transform-origin: 0% 50%;
    margin-bottom: -29rem;
  }
  img {
    width: 53rem;
    height: 53rem;
  }
`;
const Right = styled.div``;
const Title = styled.div`
  font-weight: 400;
  font-size: 18rem;
  line-height: 130%;
  margin-bottom: 4rem;
`;
const Caption = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 125%;
  color: #878EA5;
  opacity: 0.6;
`;

export const MainScreen4Card: React.FC<MainScreen4CardProps> = (props) => {

  return (
    <Wrapper>
      {props.icon}
      <Right>
        <Title>
          {props.title}
        </Title>
        <Caption>
          {props.caption}
        </Caption>
      </Right>
    </Wrapper>
  );
}
