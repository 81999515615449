import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Container, mediaBreakpoints } from "~/ui";
import iconGamburger from "~/ui/images/icon-gamburger.svg";
import iconX from "../../ui/images/icon-x.svg";
import logo from "../../ui/images/logo.png";

const Wrapper = styled.div<{ showBackground: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 68em;
  background: ${(props) => (props.showBackground ? "rgba(255, 255, 255, 0.86)" : "")};
  backdrop-filter: blur(${(props) => (props.showBackground ? "4px" : "0")});
  display: flex;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    height: 69em;
    z-index: 0;
  }

  font-size: ${1 / 14.4}vw; // 1440 / 100, 1440 - figma design screen wide
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-size: ${1 / 3.75}vw; // 375 / 100, 375 - figma design mobile screen wide
  }
  @media screen and (min-width: ${mediaBreakpoints.desktopToLarges}px) {
    font-size: 1.38889px; // {1 / 14.4}vw - pixel value on 1920 screen wide
  }

  .header-logo {
    width: 142em;
    flex-shrink: 0;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      width: 103rem;
      position: relative;
      z-index: 10;
    }
  }

  .hidden {
    visibility: hidden;
    pointer-events: none;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      display: none;
    }
  }
`;

const ContainerLocal = styled(Container)`
  max-width: 1000em;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32em);
    justify-content: space-between;
  }
`;
const Menu = styled.div<{ open: boolean }>`
  display: flex;
  font-weight: 400;
  font-size: 16rem;
  line-height: 46rem;
  column-gap: 43rem;
  margin: 0 auto;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    flex-direction: column;
    padding-top: 115rem;
    padding-bottom: 56rem;
    align-items: center;
    row-gap: 33rem;
    font-weight: 600;
    font-size: 24rem;
    line-height: 125%;
    column-gap: 0;
    border-radius: 0 0 24rem 24rem;
    transition: transform 1.5s ease-in-out;

    transform: ${(props) => (props.open ? "translateY(0%)" : "translateY(-100%)")};
    pointer-events: ${(props) => (props.open ? "auto" : "none")};
  }

  .close-menu {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15rem 18rem;
    background: #fff;
    cursor: pointer;
    display: none;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      display: block;
    }

    .x {
      width: 36rem;
    }
  }
`;
const Item = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const MobileButton = styled.div`
  display: none;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: block;
    cursor: pointer;
    position: relative;
    z-index: -1;
    .burger {
      width: 32rem;
    }
  }
`;

export const Header: React.FC = () => {
  const [showBackground, setShowBackground] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const handleLogoClick = (e: SyntheticEvent) => {
    e.preventDefault();
    window.scroll(0, 0);
  };

  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 50 && showBackground === false) {
        setShowBackground(true);
      }
      if (window.scrollY <= 50 && showBackground === true) {
        setShowBackground(false);
      }
    };

    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("scroll", handler);
    };
  }, [showBackground]);

  const handleToggleOpenStateMobileMenu = () => {
    setMobileMenuIsOpen(true);
  };
  const handleOnClickCloseMobileMenu = () => {
    if (mobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    }
  };

  return (
    <Wrapper className={"header"} showBackground={showBackground}>
      <ContainerLocal>
        <a href={"/"} onClick={handleLogoClick}>
          <img src={logo} alt={"МайТех"} className={"header-logo"} />
        </a>
        <Menu open={mobileMenuIsOpen}>
          <Item href={"#loc1"} onClick={handleOnClickCloseMobileMenu}>
            Пользователям
          </Item>
          <Item href={"/master"} onClick={handleOnClickCloseMobileMenu}>
            Специалистам
          </Item>
          <Item href={"#loc3"} onClick={handleOnClickCloseMobileMenu}>
            Дистрибьюторам
          </Item>
          <div className={"close-menu"} onClick={handleOnClickCloseMobileMenu}>
            <img src={iconX} className={"x"} />
          </div>
        </Menu>
        <a href={"/"} onClick={handleLogoClick} className={"hidden"}>
          <img src={logo} alt={"МайТех"} className={"header-logo"} />
        </a>
        <MobileButton
          onClick={handleToggleOpenStateMobileMenu}
          style={{ zIndex: mobileMenuIsOpen ? "-1" : "0" }}
        >
          <img src={iconGamburger} className={"burger"} />
        </MobileButton>
      </ContainerLocal>
    </Wrapper>
  );
};
