import React from 'react';

export const IconWebcam: React.FC = () => (
  <svg
    width={'53'}
    height={'53'}
    viewBox={'0 0 53 53'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <g clipPath={'url(#clip0_1026_10703)'}>
      <circle
        cx={'25.9479'}
        cy={'22.0833'}
        r={'16.1667'}
        stroke={'currentColor'}
        strokeWidth={'3'}
      />
      <circle
        cx={'25.9466'}
        cy={'22.0833'}
        r={'7.33333'}
        stroke={'currentColor'}
        strokeWidth={'3'}
      />
      <path
        d={'M14.4155 33.125L8.28125 46.375H43.6146L37.4803 33.125'}
        stroke={'currentColor'}
        strokeWidth={'3'}
        strokeLinejoin={'bevel'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1026_10703'}>
        <rect
          width={'44.1667'}
          height={'44.1667'}
          fill={'white'}
          transform={'translate(3.86328 4.41663)'}
        />
      </clipPath>
    </defs>
  </svg>
);
