import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import phone from '~/ui/images/screen-9-phone.png';
import { MainContainer } from './main-container';
import { MainList } from './main-list';

const Wrapper = styled.div`
  scroll-margin-top: 35vh;
  margin-bottom: 209rem;
  position: relative;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 88rem;
    overflow: hidden;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  ul li:first-child {
    max-width: 65%;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    align-items: center;
  }

  img {
    width: 473rem;
    flex-shrink: 0;
    margin-right: -72rem;
    max-width: none;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      margin-top: 111rem;
      width: 130%;
      margin-right: 0;
    }
  }
`;

const Text1 = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }
`;
const Text2 = styled.div`
  max-width: 435rem;
  width: 100%;
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 83rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
    margin-bottom: 34rem;
    max-width: 74%;
  }
`;

const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 24rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;
const Position1 = styled.div`
  display: none;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: block;
    position: absolute;
    top: -78rem;
    right: -136rem;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }
  img {
    width: 311rem;
    transform: scaleX(-1);
  }
`;

export const MainScreen9: React.FC = () => (
  <Wrapper id={'loc2'}>
    <ContainerLocal>
      <Position1>
        <img
          src={'/static/screen-10/rabochii.svg'}
          alt={'Мужик в рабочем костюме'}
        />
      </Position1>
      <Left>
        <Text1>
          Специалистам
        </Text1>
        <Divider/>
        <Text2>
          Если вы — специалист, приглашаем
          подключиться к нашему сервису
        </Text2>
        <MainList items={[
          'Получайте заказы по персональным настройкам',
          'К вашим услугам рабочий календарь, интернет-магазин запчастей и расходников, предложенных в соответствии с моделью техники клиента, с сервисом доставки до места ремонта',
          'Также у вас есть возможность работать из дома. С помощью приложения MyTech вы сможете удалённо консультировать и проводить диагностику в формате «Видеочата»',
        ]}
        />
      </Left>
      <Right>
        <img
          src={phone}
          alt={'Телефон с приложение mytech'}
        />
      </Right>
    </ContainerLocal>
  </Wrapper>
);
