import React from 'react';

export const IconWashingMachine: React.FC = () => (
  <svg
    width={'53'}
    height={'53'}
    viewBox={'0 0 53 53'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      x={'5.47656'}
      y={'2.82501'}
      width={'42.05'}
      height={'47.35'}
      stroke={'currentColor'}
      strokeWidth={'3'}
    />
    <circle
      cx={'26.5'}
      cy={'31.8'}
      r={'11.75'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M3.97656 14.575H49.0266'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M15.9023 31.8C16.5179 33.1679 16.5175 33.1681 16.5172 33.1682C16.5171 33.1683 16.5168 33.1684 16.5166 33.1685C16.5163 33.1687 16.516 33.1688 16.5159 33.1688C16.5156 33.1689 16.5157 33.1689 16.5162 33.1687C16.5172 33.1682 16.5198 33.1671 16.5238 33.1653C16.532 33.1617 16.5462 33.1556 16.5661 33.147C16.6061 33.13 16.669 33.1037 16.7527 33.0701C16.9202 33.003 17.1698 32.9076 17.4836 32.8006C18.1145 32.5855 18.9885 32.3295 19.9675 32.1601C22.0013 31.8081 24.1227 31.8903 25.6023 33L27.4023 30.6C24.907 28.7285 21.7284 28.8107 19.4559 29.204C18.2818 29.4073 17.2511 29.7103 16.5156 29.961C16.1462 30.087 15.8472 30.2011 15.6369 30.2854C15.5316 30.3275 15.4483 30.3623 15.3893 30.3875C15.3598 30.4001 15.3363 30.4103 15.3191 30.4178C15.3105 30.4216 15.3035 30.4247 15.2981 30.4271C15.2954 30.4283 15.2931 30.4293 15.2912 30.4302C15.2903 30.4306 15.2894 30.431 15.2887 30.4313C15.2883 30.4315 15.2879 30.4317 15.2877 30.4318C15.2872 30.432 15.2868 30.4322 15.9023 31.8ZM25.6023 33C28.0977 34.8716 31.2763 34.7893 33.5488 34.396C34.7229 34.1928 35.7536 33.8898 36.4891 33.639C36.8585 33.5131 37.1575 33.399 37.3678 33.3147C37.4731 33.2725 37.5564 33.2377 37.6154 33.2126C37.6449 33.2 37.6684 33.1898 37.6856 33.1822C37.6942 33.1785 37.7012 33.1754 37.7066 33.173C37.7093 33.1718 37.7116 33.1707 37.7135 33.1699C37.7144 33.1695 37.7152 33.1691 37.716 33.1688C37.7164 33.1686 37.7168 33.1684 37.717 33.1683C37.7175 33.1681 37.7179 33.1679 37.1023 31.8C36.4868 30.4322 36.4872 30.432 36.4875 30.4318C36.4876 30.4318 36.4879 30.4316 36.4881 30.4316C36.4884 30.4314 36.4887 30.4313 36.4888 30.4313C36.4891 30.4311 36.489 30.4312 36.4885 30.4314C36.4875 30.4318 36.4849 30.433 36.4808 30.4348C36.4727 30.4383 36.4585 30.4445 36.4385 30.453C36.3986 30.4701 36.3357 30.4964 36.252 30.5299C36.0845 30.5971 35.8349 30.6925 35.5211 30.7995C34.8902 31.0146 34.0162 31.2705 33.0372 31.44C31.0034 31.792 28.882 31.7098 27.4023 30.6L25.6023 33Z'}
      fill={'currentColor'}
    />
  </svg>
);
