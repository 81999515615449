import React from 'react';

export const IconCase: React.FC = () => (
  <svg
    width={'53'}
    height={'53'}
    viewBox={'0 0 53 53'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M47.4766 15.4583L47.4766 44.1666L7.72656 44.1666L7.72656 15.4583L47.4766 15.4583Z'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M7.72656 24.2916H47.4766'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M18.7695 15.4583V8.83325H36.4362V15.4583'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
    <path
      d={'M34.2266 24.2916V30.9166H20.9766V24.2916'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinejoin={'bevel'}
    />
  </svg>
);
