import React from 'react';

export const IconInfo: React.FC = () => (
  <svg
    width={'53'}
    height={'53'}
    viewBox={'0 0 53 53'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <circle
      cx={'26.5003'}
      cy={'26.5'}
      r={'22.1091'}
      transform={'rotate(180 26.5003 26.5)'}
      stroke={'currentColor'}
      strokeWidth={'3'}
    />
    <path
      d={'M26.5 28.8609L26.5 17.0564'}
      stroke={'currentColor'}
      strokeWidth={'3'}
      strokeLinecap={'square'}
      strokeLinejoin={'bevel'}
    />
    <circle
      cx={'26.4985'}
      cy={'35.9436'}
      r={'2.36091'}
      transform={'rotate(180 26.4985 35.9436)'}
      fill={'currentColor'}
    />
  </svg>
);
