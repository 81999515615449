import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import phone from '~/ui/images/screen-5-phone.png';
import { MainContainer } from './main-container';

const Wrapper = styled.div`
  padding-top: 106rem;
  padding-bottom: 97rem;
  margin-bottom: 251rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 50rem;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: -822rem;
    margin-bottom: 325rem;
  }

  img {
    width: 473rem;
    flex-shrink: 0;
    margin-right: -124rem;
    max-width: none;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Text1 = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }
`;
const Text2 = styled.div`
  max-width: 612rem;
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 90rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
    margin-bottom: 40rem;
  }
`;
const Text3 = styled.div`
  font-weight: 500;
  font-size: 32rem;
  line-height: 140%;
  margin-bottom: 24rem;
  max-width: 687rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 600;
    font-size: 24rem;
    line-height: 125%;
    margin-bottom: 24rem;
    margin-top: 599rem;
    text-align: right;
    max-width: 80%;
    margin-left: auto;
  }
`;
const Text4 = styled.div`
  font-weight: 300;
  font-size: 20rem;
  line-height: 140%;
  max-width: 604rem;
  color: rgba(36, 40, 91, 0.7);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 16rem;
    line-height: 140%;
    margin-bottom: 40rem;
    text-align: right;
    max-width: 80%;
    margin-left: auto;
  }

`;

const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 20rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;
const Position1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    transform: scale(0.6);
    transform-origin: 0% 50%;
    bottom: -190rem;
  }
  img {
    width: 380rem;
  }
`;

export const MainScreen3: React.FC = () => (
  <Wrapper>
    <ContainerLocal>
      <Position1>
        <img
          src={'/static/screen-3/schedule-man.svg'}
          alt={'Человек в расписании'}
        />
      </Position1>
      <Left>
        <Text1>
          С нами вы решите любой вопрос в два-три клика
        </Text1>
        <Divider/>
        <Text2>
          Теперь вам не нужно тратить время на изучение отзывов и общение со
          специалистами, сравнивать стоимость запчастей и услуг. Мы берём на
          себя подбор мастеров и отвечаем за результаты их работы
        </Text2>
        <Text3>
          Мы напомним даже о замене фильтра!
        </Text3>
        <Text4>
          Разместив информацию о брендах и моделях всей техники в вашем доме, вы
          будете получать персональные рекомендации по уходу и настройке ваших
          устройств
        </Text4>
      </Left>
      <Right>
        <img
          src={phone}
          alt={'Телефон с приложение mytech'}
        />
      </Right>
    </ContainerLocal>
  </Wrapper>
);
