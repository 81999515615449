import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Footer, mediaBreakpoints } from '~/ui';
import { Header } from './header';
import { MainContainer } from './main-container';
import { MainScreen1 } from './main-screen-1';
import { MainScreen10 } from './main-screen-10';
import { MainScreen11 } from './main-screen-11';
import { MainScreen2 } from './main-screen-2';
import { MainScreen3 } from './main-screen-3';
import { MainScreen4 } from './main-screen-4';
import { MainScreen5 } from './main-screen-5';
import { MainScreen6 } from './main-screen-6';
import { MainScreen7 } from './main-screen-7';
import { MainScreen8 } from './main-screen-8';
import { MainScreen9 } from './main-screen-9';

const Wrapper = styled.div`
  .footer {
    font-size: ${1 / 14.4}vw; // 1440 / 100, 1440 - figma design screen wide
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      font-size: ${1 / 3.75}vw; // 375 / 100, 375 - figma design mobile screen wide
    }
    @media screen and (min-width: ${mediaBreakpoints.desktopToLarges}px) {
      font-size: 1.38889px; // {1 / 14.4}vw - pixel value on 1920 screen wide
    }
  }
`;

export const Main: React.FC = (props) => {
  useEffect(
    () => {
      document.querySelector('html').style.scrollBehavior = 'smooth';
      return () => {
        document.querySelector('html').style.scrollBehavior = 'auto';
      };
    },
    [],
  );

  return (
    <Wrapper>
      <MainScreen1 id={'screen1'}/>
      <MainScreen2/>
      <MainScreen3/>
      <MainScreen4/>
      <MainScreen5/>
      <MainScreen6/>
      <MainScreen7/>
      <MainScreen8/>
      <MainScreen9/>
      <MainScreen10/>
      <MainScreen11/>
      <Footer ContainerFromProps={MainContainer}/>
      <Header/>
    </Wrapper>
  );
};
