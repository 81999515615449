import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #f6f6fb;
  display: flex;
  flex-direction: column;
`;

export const Oferta = () => (
  <Wrapper>
    <Typography
      sx={{
        fontSize: ["20px", "24px", "28px", "32px", "32px"],
        marginBottom: "30px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      Пользовательское соглашение
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      Данное пользовательское соглашение (далее — Соглашение) определяет правила
      использования платформы MyTech (далее – Платформа).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      В части Заказа сервисов по установке Техники, диагностики и ремонту
      Техники, Видеоконсультаций по эксплуатации Техники Соглашение является
      официальным предложением (публичной офертой, в соответствии с п. 1 ст. 435
      ГК РФ) Общества с ограниченной ответственностью «Техника Вашего дома»(ОГРН
      1215000106133, ИНН 5047257258, адрес места нахождения - 141402, Россия,
      Московская Обл., г. Химки, ул. Репина, д. 2/27, этаж 9, офис 903)
      возмездного оказания услуг по производству установки, диагностики,
      ремонта, видеоконсультаций по вопросам эксплуатации бытовой, телевизионной
      и иной потребительской техники и цифровых устройств.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      Использование любого функционала Платформы, в т.ч. регистрация учетной
      записи,означает согласие с условиями Соглашения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      В случае, если Вы не согласны с каким-либо из условий Соглашения,
      пожалуйста, воздержитесь от любого использования Платформы.
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      1.Термины и определения
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      В настоящем Соглашении применяются термины, имеющие нижеследующее
      значение.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Администрация</b> — Администрация Платформы - общество с ограниченной
      ответственностью «Техника Вашего дома»(ОГРН 1215000106133, ИНН 5047257258,
      адрес места нахождения - 141402, Россия, Московская Обл., г. Химки, ул.
      Репина, д. 2/27, этаж 9, офис 903), юридическое лицо, зарегистрированное
      по законодательству Российской
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Платформа</b> – приложение MyTech для мобильных устройств,
      представляющий собой совокупность программно-аппаратных средств,
      содержащих в себе информационную систему данных и объектов
      интеллектуальной собственности (в том числе, программ для ЭВМ, баз данных,
      графическое оформление интерфейса (дизайн) и др.), доступ к которому
      обеспечивается с различных пользовательских устройств, подключенных к сети
      Интернет, посредством специального программного обеспечения. Платформа
      является Интернет-ресурсом, предназначенным для получения информации о
      различных работах и/или услугах, а также для размещения и принятия Заказов
      одними Пользователями (Исполнителями) другим Пользователям (Заказчикам), в
      том числе для заключения между ними Договоров, осуществления
      взаимодействия и проведения взаиморасчетов между сторонами таких
      Договоров.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Пользователь</b> – совершеннолетнее, полностью дееспособное физическое
      лицо, использующее Платформу
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Персональные данные Пользователя</b> — любая информация, относящаяся к
      прямо или косвенно определенному или определяемому Пользователю
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Учетная запись</b> — запись в базе данных, содержащая по крайней мере
      ее наименование (логин) и пароль, обеспечивающие идентификацию конкретного
      зарегистрированного Пользователя и обеспечение его доступа к услугам и
      сервисам Платформы;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Контент</b> — вся информация и программное обеспечение, а также
      порождаемые этим программным обеспечением аудио-визуальные материалы, в
      том числе текст, фотографические произведения, в т.ч. в цифровом формате,
      аудио- и видеозаписи, рисунки и изображения, интерактивные приложения,
      игры, форумы, блоги, иные публикации в Платформе, ссылки на внешние
      ресурсы и т.д., доступные в Платформе его посетителям и Пользователям в
      интерактивном режиме
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Видеоконсультация</b> — часть Платформы, которая содержит функционал,
      обеспечивающий возможность Пользователям обратиться к специалистам
      посредством аудио- видеосвязи, а также при помощи обмена текстовыми
      сообщениями, за получением консультации и рекомендации, предоставляемые в
      ответ на обращения Пользователей, по вопросам эксплуатации Техники.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Оферта</b> – текст настоящего Соглашения, который вместе с размещенным
      Заказчиком через Платформу Заказом на выполнение конкретного перечня
      услуг, а также информации о сроках и порядке их выполнения представляют
      собой предложение Исполнителя.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Акцепт Оферты</b> – полное и безоговорочное принятие Оферты Заказчиком,
      путем осуществления действий, направленных на принятие к исполнению
      Оферты, в том числе с использованием Платформы (направление Заказа через
      платформу, подтверждение Заказа).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Заказчик</b> – физическое лицо, достигшее возраста 18 лет,
      зарегистрированное в качестве Пользователя и принявшее условия Соглашения
      использования Платформы для целей акцепта Оферты на приобретение доступных
      посредством Платформы услуг на условиях настоящей Оферты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Исполнитель</b> – Общество с ограниченной ответственностью «Техника
      Вашего дома», ОГРН 1215000106133, ИНН 5047257258, адрес места нахождения -
      141402, Россия, Московская Обл., г. Химки, ул. Репина, д. 2/27, этаж 9,
      офис 903, и иные физические и юридические лица, привлекаемые Исполнителем
      для непосредственного оказания услуг по производству установки,
      диагностики, ремонта, видеоконсультаций по эксплуатации бытовой,
      телевизионной и иной потребительской техники и цифровых устройств
      (Соисполнители).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Договор</b> – договор между Заказчиком и Исполнителем на предоставление
      услуг по проведению установки, диагностики, ремонта, видеоконсультаций по
      эксплуатации техники, который заключается посредством акцепта Оферты.
      Договор заключается и становится обязательным для обеих сторон после
      Акцепта Оферты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Техника</b> — бытовая, телевизионная техника и цифровые устройства, в
      том числе оборудование необходимое для обеспечения их работы.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Заказ</b> – выраженное посредством Платформы обращение Заказчика к
      Исполнителю с целью оказания Исполнителем отдельных услуг по установке
      Техники, по диагностике Техники, ремонту Техники, видеоконсультации по
      эксплуатации Техники. Заказ и акцептирование осуществляется путем вызова
      сотрудника Исполнителя и/или Соисполнителей посредством функционала
      Платформы (нажатия графического элемента «Вызов Мастера», «Установка»,
      «Видеоконсультация)..
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "14px", "18px", "20px", "20px"],
        marginBottom: "20px",
      }}
    >
      <b>Стоимость Заказа</b> – стоимость услуг Исполнителя в отдельном Заказе,
      определяется исходя из состава и стоимости услуг Исполнителя (прайс-листа
      Исполнителя).
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      2. ДОГОВОР-ОФЕРТА ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      1. Предмет договора
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1.1. Исполнитель обязуется по запросу Заказчика оказать последнему (или
      иному лицу по указанию Заказчика) услугу на условиях настоящей Оферты, а
      Заказчик обязуется принять и оплатить такие услуги Исполнителя.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1.2. Подробный перечень услуг, которые Исполнитель (Соисполнители) должен
      (должны) оказать Заказчику, объем таких услуг и сроки указаны в Заказе
      Заказчика, размещенном через Платформу. Стоимость услуг исполнителя
      определяется исходя из Стоимости Заказа. Указанный Заказ, размещенный
      через Платформу, после принятия (Акцепта) Исполнителем (Исполнителями)
      предложения (Оферты) Заказчика становится неотъемлемой частью настоящего
      Договора.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1.3. Исполнитель (Соисполнители) оказывает (оказывают) услуги по месту
      нахождения Заказчика, либо ином указанным им в Заказе месте.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      2. Стоимость услуг и порядок оплаты
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.1. Стоимость услуг, из числа доступных к заказу посредством Платформы,
      определяется и принимается Сторонами в момент размещения Заказчиком Заказа
      в Платформе и акцепта Исполнителем соответствующего Заказа.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.2. Стоимость услуг по Договору определяется из стоимости каждой
      выбранной Заказчиком услуги из числа доступных к заказу у Исполнителя и
      представляет собой общую сумму всех выбранных Заказчиком услуг Исполнителя
      на момент оформления Заказа. При этом, если Исполнителем в процессе
      оказания услуг будет установлена необходимость оказания большего объема
      услуг, чем указано в Заказе, то стоимость такого Заказа подлежит пересчету
      исходя из объема услуг, подлежащих оказанию. В таком случае, Заказчик
      обязуется изменить объем услуг в Заявке посредством Платформы (через
      мобильное приложение/сайт или колл-центр).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.3. В случае потребности Заказчика в услугах, которые могут быть оказаны
      Исполнителем, но не поименованы в вышеуказанном перечне услуг Исполнителя,
      Стороны вправе согласовать условия оказания таких услуг (включая сроки и
      стоимость) в порядке, аналогичном для услуг Исполнителя, предусмотренных
      прайс-листом Исполнителя (путем отражения таких услуг в Заказе).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.4. В случае отказа Заказчика произвести изменение объема Заказа,
      Исполнитель вправе отказаться от исполнения Заказа без компенсации
      каких-либо убытков, штрафов и неустоек Заказчику, в связи с
      несогласованием существенных условий договора (п. 1 ст. 432 ГК РФ).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.5. Оплата услуг производится Заказчиком или иным указанным им лицом,
      непосредственно после их оказания и может осуществляться одним из
      следующих способов
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      2.5.1. Посредством Платформы в безналичной форме. При таком порядке
      взаиморасчётов Заказчик оплачивает услугу в соответствии с Правилами
      безналичных расчетов.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      2.5.2. Посредством погашения кода (уникального набора знаков для доступа к
      Платформе), предварительно приобретенного Заказчиком. В случае, если
      номинал такого кода меньше стоимости предоставляемых Заказчику
      услуг,Заказчик обязуется оплатить услуги Исполнителя в соответствии с п.
      2.5. Оферты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.6. В случае возникновения у Заказчика задолженности перед Исполнителем
      по оплате оказанных по договору возмездного оказания Услуг, а также при
      наличии иных задолженностей, подлежащих уплате в пользу Исполнителя,
      последний, являясь получателем средств в смысле Федерального закона от
      27.06.2011 N 161-ФЗ, вправе предъявить требование о совершении платежа в
      сумме задолженности с привязанной Заказчиком в Сервисе банковской карты,
      на что Заказчик заранее дает свой акцепт.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.7. Выбор соответствующей формы оплаты производится Заказчиком в
      интерфейсе Платформы. При этом, при невозможности по любым причинам
      произвести безналичную оплату (включая, но не ограничиваясь, временная
      техническая недоступность данной функциональности, временная или
      постоянная невозможность безналичной оплаты, недостаточность средств на
      банковской карте, включая, но не ограничиваясь) Заказчик производит оплату
      наличными денежными средствами непосредственно Исполнителю.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.8. Исполнитель не имеет доступа к указанным Заказчиком данным банковской
      карты и не несет ответственности за сохранность и конфиденциальность
      передаваемых данных при проведении безналичной оплаты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.9. Оплата услуг производится посредством защищенного окна с платежной
      страницей, в который Пользователю необходимо ввести данные своей
      банковской карты. Для подтверждения действительности карты осуществляется
      списание суммы в размере 1 рубля, которая возвращается Пользователю после
      подтверждения наличия данной суммы на карте. Для дополнительной
      аутентификации держателя карты используется протокол 3D Secure. Если банк
      Пользователя поддерживает данную технологию, Пользователь перенаправляется
      на сервер банка для дополнительной идентификации. Информацию о правилах и
      методах дополнительной идентификации можно уточнить в банке, выдавшем
      банковскую карту. При повторной транзакции вводить данные не нужно, платеж
      произойдет автоматически с добавленной ранее карты Пользователя.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.10. Чек о транзакции будет отправлен на номер Пользователя, указанный на
      этапе регистрации в Платформе.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.11. Администрация не гарантирует отсутствия ошибок и сбоев в отношении
      предоставления возможности безналичной оплаты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2.12. Процессинговый центр защищает и обрабатывает данные банковской карты
      Пользователя по стандарту безопасности PCI DSS 3.2. Передача информации в
      платежный шлюз происходит с применением технологии шифрования SSL.
      Дальнейшая передача информации происходит по закрытым банковским сетям,
      имеющим наивысший уровень надежности. Для дополнительной аутентификации
      держателя карты используется протокол 3D Secure.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      3. Сроки и условия оказания услуг
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.1. Исполнитель обязан приступить к оказанию услуг в сроки, указанные
      Заказчиком в Заказе.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.2. Оказание Услуг Исполнителем включает в себя диагностику Техники, на
      основании которой Исполнитель составляет перечень услуг по ремонту
      Техники. Заказчик согласен, что при проведении диагностики Исполнителем,
      возможно обнаружение скрытых дефектов, не заявленных Заказчиком. В случае
      обнаружения таковых, Исполнитель сообщает об этом Заказчику. Заказчик
      согласен, что все неисправности и выявленные скрытые дефекты, возникли до
      проведения диагностики Исполнителем.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.3. Услуги по диагностике Техники включают в себя проверку основных
      элементов устройства в соответствии с внутренним регламентом Исполнителя
      по диагностике Техники.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.4. Продолжительность выполнения ремонта Техники определяется
      Исполнителем на основании технической сложности работ, а также сроков
      поставки запасных частей.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.5. Все материалы и оборудование, необходимые для оказания услуг,
      предоставляет Исполнитель, если иного не будет согласовано между сторонами
      настоящего Договора дополнительно. При этом, оказание Исполнителем услуг с
      применением предоставленных Заказчиком материалов и оборудования не
      изменяет стоимость Договора.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.6. Запасные части, иные расходные материалы предоставляются Исполнителем
      исходя из стоимости запасных частей и расходных материалов, размещенной
      посредством интерфейса Платформы.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.7. Исполнитель несет ответственность за ненадлежащее качество
      предоставленных им материалов, запасных частей, или оборудования.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.8. Прием оказанных Исполнителем услуг осуществляется Заказчиком
      посредством Платформы, где:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      3.8.1. По завершении оказания услуг Исполнитель со своей стороны отмечает
      посредством Платформы об окончании оказания услуг, о чем Заказчик
      уведомляется в личном кабинете Платформы.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      3.8.2. В случае, если Заказчик не уведомил Исполнителя о своем несогласии
      с качеством или объемом оказанных Исполнителем услуг в течение 24
      (двадцати четырех) часов после их завершения, Услуги Исполнителя считаются
      принятыми без каких-либо замечаний и подлежащими оплате в полном объеме.
      Моментом начала течения срока является дата и время транзакции о списании
      (попытки списания) денежных средств с банковской карты Заказчика по
      соответствующему заказу, а при заказах, оплачиваемых наличными денежными
      средствами, согласно сведениям Платформы
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      3.8.3. Уведомление об отказе от приемки Услуг в обязательном порядке
      осуществляется в адрес Исполнителя одним из следующих способов:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "45px",
      }}
    >
      3.8.3.1. путем уведомления Исполнителя посредством телефонного звонка, или
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "45px",
      }}
    >
      3.8.3.2. через любой из доступных каналов связи Исполнителя, указанный
      посредством Платформы
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.9. При обнаружении Заказчиком недостатков выполненных работ Стороны
      оговаривают соразмерный срок для их устранения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.10. Заказчик принимает на себя риск, связанный с возможным выявлением в
      изделии дефектов, не указанных при приеме техники, а также риск возможной
      полной или частичной утраты работоспособности Техники или отдельных её
      узлов и блоков в случае установления факта нарушения потребителем условий
      эксплуатации Техники, наличия следов коррозии, попадания влаги либо
      механических повреждений.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.11. Исполнитель не несёт ответственности за сохранность данных в технике
      (записная книжка, фотографии, медиа файлы, игры, операционная система и
      т.д.). Кроме того, при проведении диагностики/ремонта все виды защитных
      пленок на экраны, которые установлены дополнительно, могут быть повреждены
      или утрачены. Исполнитель не несет ответственности и не возмещает
      стоимость такого типа изменений.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3.12. При необходимости Исполнитель имеет право привлекать к оказанию
      услуг по ремонту техники Заказчика третьих лиц, а также имеет право
      передавать им всю необходимую для эффективного проведения работ информацию
      по техническому устройству Заказчика.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      4. Сервис Видеоконсультаций
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.1. Cервис Видеоконсультаций, доступный в Платформе, предоставляет
      возможность Пользователю приобретать Видеоконсультации у специалистов.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.2. Возможность приобрести Видеоконсультацию доступна всем
      зарегистрированным Пользователям.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.3. Стоимость услуги указывается в рублях и доводится до сведения
      Пользователя в интерфейсе Платформы.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.4. Видеоконсультации могут оказываться c 10 утра до 20 часов по
      московскому времени, но с учетом графика работы специалистов. В случае,
      если в момент размещения Пользователем Заказа специалист свободен и готов
      принять Заказ Пользователя, то устанавливается диалог между специалистом и
      Пользователем (чат и аудио- и/или видео- общение).
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.5. Началом оказания Видеоконсультации является момент установления
      диалога между специалистом и Пользователем- чат и аудио- и/или видео-
      общение.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.6. Продолжительность диалога между специалистом и Пользователем при
      оказании Видеоконсультации ограничена 35 (тридцатью пятью) минутами.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.7. Пользователь самостоятельно несет ответственность за применение (не
      применение) рекомендаций специалиста, данных в процессе оказания
      Видеоконсультации.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4.8. В случае, если Пользователь в течение 24 (двадцати четырех) часов с
      момента оказания Видеоконсультации, не направит претензию в порядке,
      установленном в настоящей оферте, Видеоконсультация считается оказанной
      надлежащим образом и принятой Пользователем.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      5. Права и обязанности сторон
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      5.1. Исполнитель имеет право:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.1.1. В безусловном порядке изменять (дополнять) условия настоящего
      договора-оферты и приложений к нему. Условия по настоящему Договору не
      подлежат изменению с момента размещения Заказчиком Заказа.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.1.2. По собственному усмотрению изменять тарифы и условия предоставления
      услуг. При этом Стороны руководствуются тем, что новые тарифы не
      распространяются на оплаченные услуги.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.1.3. Требовать от Заказчика своевременной и полной оплаты услуг в
      соответствии с настоящим Договором.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.1.4. Отказать Заказчику в предоставлении услуг по Договору, если
      Заказчик нарушает сроки, условия оплаты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      5.2. Исполнитель обязан:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.2.1. Оказать Заказчику услуги по установке, диагностике и ремонту
      техники согласно Заказу.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.2.2. Качественно выполнить работы, предусмотренные настоящим договором в
      установленный срок.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.2.3. Организовать работы по диагностике и ремонту с соблюдением норм
      безопасности и требований по охране труда. В ходе ремонта использовать
      качественные детали, изделия, материалы, свое оснащение и инструменты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.2.4. Обеспечить сохранность оборудования на период ремонта вне
      расположения Заказчика
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      5.3. Заказчик имеет право:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.3.1. Требовать от Исполнителя выполнения его обязательств по Договору в
      срок и с надлежащим качеством.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.3.2. В любое время проверять ход и качество работы, выполняемой
      Исполнителем, при условии отсутствия создания препятствий для оказания
      услуг Исполнителем.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      5.4. Заказчик обязан:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.4.1. Самостоятельно ознакомиться с данным договором публичной оферты.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.4.2. При проведении работ в месте расположения Заказчика - предоставить
      место, достаточное для проведения диагностики и последующего ремонта
      техники.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      5.4.3. Оплатить услуги Исполнителя
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      6. Ответственность сторон
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      6.1. В случае нарушения Исполнителем обязательств по Договору Заказчик
      вправе требовать возмещения только реального ущерба. Упущенная выгода
      возмещению не подлежит.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      6.2. За неисполнение или ненадлежащее исполнение условий настоящего
      Договора стороны несут ответственность в соответствии с действующим
      законодательством Российской Федерации, с учетом п.5.1 настоящего Договора
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      6.3. Стороны освобождаются от ответственности за частичное или полное
      неисполнение обязательств по настоящему договору, если оно явилось
      следствием природных явлений, действия внешних объективных факторов и
      прочих обстоятельств непреодолимой силы и если эти обстоятельства (то есть
      такие обстоятельства, которые независимы от воли Сторон, не могли быть ими
      предвидены в момент заключения Договора и предотвращены разумными
      средствами при их наступлении) непосредственно повлияли на исполнение
      настоящего договора.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      6.4. Срок исполнения обязательств по настоящему договору отодвигается
      соразмерно времени, в течение которого действовали обстоятельства
      непреодолимой силы. Наступление указанных обстоятельств продлевает срок
      исполнения договорных обязательств на период, который в целом
      соответствует сроку действия наступившего обстоятельства, и разумному
      сроку для его устранения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      7. Гарантийные обязательства
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      7.1. Срок гарантийных обязательств на качество выполненных работ
      устанавливается в каждом случае индивидуально в зависимости от уровня
      сложности ремонта и использованных запасных частей, в соответствии с
      рекомендациями производителей.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      7.2. Гарантия Исполнителя распространяется только на непосредственно
      Исполнителем оказанные услуги, замененные запасные части.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      7.3. Гарантия не предоставляется в случае отказа Заказчиком провести
      ремонтные работы в полном объеме; обнаружении следов неквалифицированного
      вмешательства; наличии в (на) изделии следов коррозии произошедших в виду
      воздействия жидкости, продуктов жизнедеятельности насекомых, высокой
      влажности воздуха или посторонних предметов внутри.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      8. Срок действия, изменение и расторжение Договора
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      8.1. Договор вступает в силу с момента акцепта оферты и действует до
      момента окончания оказания услуг Исполнителем.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      8.2. Заказчик соглашается и признает, что внесение изменений в оферту
      влечет за собой внесение этих изменений в заключенный и действующий между
      Заказчиком и Исполнителем договор. Заказчик обязан самостоятельно
      отслеживать любые изменения в условиях настоящего Договора. Если Заказчик
      не согласен с какими-либо изменениями, он обязан незамедлительно сообщить
      об этом Исполнителю. Если Заказчик не сообщил о своем несогласии с
      изменениями в течении 10 дней после опубликования новой версии Оферты,
      Заказчик считается принявшим данные изменения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      8.3. Договор может быть расторгнут досрочно:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      8.3.1. По инициативе любой из сторон в любое время с предварительным
      письменным уведомлением другой стороны посредством способов связи,
      предусмотренных Платформой, за пятнадцать календарных дней до
      предполагаемой даты расторжения. Договор считается расторгнутым по
      истечении 15 календарных дней со дня направления стороной, отказывающейся
      от исполнения договора, другой стороне соответствующего письменного
      уведомления. В течение указанного срока стороны производят все
      взаиморасчеты по договору.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      8.3.2. По иным основаниям, предусмотренным настоящей офертой и/или
      действующим законодательством РФ.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      8.4. Прекращение срока действия договора по любому основанию не
      освобождает стороны от ответственности за нарушения условий договора,
      возникшие в течение срока его действия.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      9. Разрешение споров
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      9.1. Все споры и разногласия, возникающие между сторонами в рамках
      Договора или в связи с ним, в том числе касающиеся его заключения,
      изменения, исполнения, нарушения, расторжения или признания
      недействительным, подлежат разрешению мировым судьей или в суде общей
      юрисдикции в соответствии с законодательством РФ.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      10. Заключительные положения
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.1. Стороны признают, что документы, оформленные средствами электронной
      связи (e-mail), а также посредством действий с использованием Платформы,
      имеют равную юридическую силу с документами на бумажных носителях,
      подписанными собственноручно подписями уполномоченных лиц и заверенными
      оттиском печати.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.2. Если иное не предусмотрено законом, заявления, уведомления,
      извещения, требования или иные юридически значимые сообщения, с которыми
      закон или сделка связывает наступление гражданско-правовых последствий для
      другого лица, влекут для этого лица такие последствия с момента доставки
      соответствующего сообщения ему или его представителю. Сообщение считается
      доставленным и в тех случаях, если оно поступило лицу, которому оно
      направлено (адресату), но по обстоятельствам, зависящим от него, не было
      ему вручено или адресат не ознакомился с ним.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.3. Исполнитель не принимает на себя никаких условий и обязательств, за
      исключением указанных в оферте, кроме случаев, когда такие условия или
      обязательства зафиксированы в письменном виде и подписаны сторонами.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.4. В случае если одно или более положений оферты являются по какой-либо
      причине недействительными, не имеющими юридической силы, такая
      недействительность не оказывает влияния на действительность любого другого
      положения оферты (договора), которые остаются в силе.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.5. Не вступая в противоречие с условиями оферты, Заказчик и Исполнитель
      вправе в любое время оформить договор на оказание услуг в форме
      письменного, двухстороннего документа.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      10.6. Текст настоящего документа, а также непосредственный Оферта
      Заказчика на оказание необходимых ему услуг, иные документы, связанные с
      исполнением Исполнителем предусмотренных Договором услуг (включая
      прайс-листы Исполнителя на оказываемые услуги, запасные части, материалы)
      - являются неотъемлемыми частями друг друга и образуют заключенный между
      Заказчиком и Исполнителем Договор с момента Акцепта Исполнителем Оферты
      Заказчика.
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      3. РЕГИСТРАЦИЯ В ПЛАТФОРМЕ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1. Пользователь проходит процедуру регистрации или авторизации в Платформе
      путем ввода своего номера мобильного телефона, являющегося логином, а так
      же кода, который он получает на мобильный телефон. После прохождения
      процедуры авторизации Пользователю автоматически присваивается цифровой
      код. При повторном входе в Платформу авторизация происходит автоматически.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2. Использование Платформы невозможно без процедуры регистрации.
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      4. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1. Регистрируясь в Платформе или предоставляя свои персональные данные
      иным способом, Пользователь подтверждает свое согласие на сбор и обработку
      персональных данных Оператором.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2. Под обработкой персональных данных понимается любое действие или
      совокупность действий, совершаемых с использованием средств автоматизации
      или без использования таких средств с персональными данными, включая сбор,
      запись, систематизацию, накопление, хранение, уточнение (обновление,
      изменение), извлечение, использование, передачу третьим лицам,
      уполномоченным Оператором, в том числе с последующей обработкой
      исключительно по поручению Оператора, трансграничную передачу на
      территорию государств, обеспечивающих адекватную защиту персональных
      данных, обезличивание, блокирование, удаление, а также уничтожение
      персональных данных (ФИО, номер мобильного телефона, адрес электронной
      почты, дата и место рождения, гражданство, email, паспортные данные, адрес
      регистрации), с целью использования функционала Платформы, участия в
      акциях и иных мероприятиях, организованных по заказу Оператора и ведения
      базы Пользователей, анализа статистики, изучения потребительского спроса и
      поведения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3. Дополнительно Пользователем может быть дано согласие на обработку
      предоставленных им персональных данных в целях продвижения товаров, работ,
      услуг Оператора, получения рекламной и маркетинговой информации о
      продуктах, акциях, подарках, образцах продукции и других рекламных
      предложениях путем направления СМС сообщений, входящих звонков, рассылки
      по электронной почте, через социальные сети и мессенджеры (включая, но, не
      ограничиваясь, Whatsapp, Telegram, Viber).
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      5. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1. Все права на Платформу в целом, на их Контент, базы данных и программы,
      обеспечивающие функционирование Платформы, принадлежат Оператору, если
      прямо не указано иное.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      2. Не допускается копирование Контента без предварительного согласия
      Оператора. При этом допускается частичное цитирование текстовых
      материалов, размещенных в Платформе при условии размещения ссылки как на
      первоисточник.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      3. Размещая в Платформе какие-либо материалы от своего имени Пользователь
      заявляет и гарантирует, что указанные материалы не нарушают права третьих
      лиц, в т.ч. авторские и/или исключительные права, право на изображения
      граждан и т.д., а также что им получены все необходимые согласия на
      размещение таких материалов, если такое применимо. В случае, если будет
      выявлено, что размещенный Пользователем материал нарушает какие-либо права
      третьих лиц, ответственность за такое нарушение Пользователь несет
      единолично.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      4. Дополнительно Оператор обращает внимание Пользователя, что размещение в
      Платформе любой информации о третьих лицах, в т.ч. их изображения,
      персональные данные, сведения о частной жизни и т.д. возможно только после
      получения соответствующего согласия от такого лица на распространение
      указанных сведений.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: 0,
        paddingLeft: "15px",
      }}
    >
      5. Размещая в Платформе какие-либо материалы от своего имени, обладателем
      исключительных прав на которые является он сам, Пользователь дает свое
      согласие на безвозмездное использование таких материалов Оператором сроком
      на 50 лет любым способом, не противоречащим законодательству РФ, в том
      числе в коммерческих целях или использования в рекламе, включая размещение
      таких материалов в открытом доступе на Сайте, Приложении или иных
      ресурсах, принадлежащих Оператору, с правом редактирования и/или
      переработки материалов.
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      6. ПОРЯДОК РАБОТЫ С ПЛАТФОРМОЙ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1. Пользователь обязуется соблюдать следующие правила при работе с
      Приложением:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (1) соблюдать все обязательства, взятые на себя Пользователем в связи с
      присоединением к данному Соглашению;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (2) предоставлять достоверные о себе данные для создания личного аккаунта
      (кабинета);
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (3) не выдавать себя за какое-либо другое лицо, включая, однако не
      ограничиваясь, не предоставлять какие-либо данные третьих лиц (без
      получения от них прямого, предварительного и осознанного согласия) для
      создания личного аккаунта (кабинета);
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (4) информировать Оператора о хищении логинов, паролей или каких-либо
      других ключей доступа Пользователя к личному аккаунту (кабинету);
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (5) не предоставлять третьим лицам доступ к своему аккаунту (кабинету)
      и/или логины, пароли или другие ключи доступа;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (6) не совершать какие-либо действия (с использованием средств
      автоматизации или без таких средств), направленные на сбор каких-либо
      персональных данных других Пользователей;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (7) не предпринимать какие-либо действия и не помогать третьим лицам в
      совершении действий, направленных на подрыв работы Платформы, включая,
      однако не ограничиваясь, (а) загружать вирусы или вредоносный код; (б)
      совершать действия, которые могут привести к неработоспособности
      Платформы, к нарушению нормальной работы Платформы или её программного
      обеспечения.
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (8) не предпринимать какие-либо иные действия, которые являются
      незаконными, мошенническими, дискриминационными или вводящими в
      заблуждение.
    </Typography>
    <Typography
      sx={{
        fontSize: ["16px", "18px", "20px", "26px", "26px"],
        marginBottom: "15px",
        fontWeight: "bold",
        marginTop: "15px",
      }}
    >
      7.ОТВЕТСТВЕННОСТЬ
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "15px",
      }}
    >
      1.Ни при каких обстоятельствах Оператор или ее представители не несут
      ответственность перед Пользователем или перед любыми третьими лицами:
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (1) за любой косвенный, случайный, неумышленный ущерб, включая упущенную
      выгоду или потерянные данные, вред чести, достоинству или деловой
      репутации, вызванный использованием Платформы, услуг или иных материалов,
      к которым Пользователь или иные лица получили доступ с помощью Платформы;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (2) за действия других пользователей или исполнителей, за товары/услуги,
      предоставляемые третьими лицами или другими пользователями (даже если
      доступ к ним был предоставлен через Платформу), к которым Пользователь
      получил доступ;
    </Typography>
    <Typography
      sx={{
        fontSize: ["14px", "16px", "18px", "20px", "20px"],
        marginBottom: "20px",
        paddingLeft: "30px",
      }}
    >
      (3) в случаях, прямо предусмотренных условиями настоящего Соглашения или
      нормой действующего законодательства.
    </Typography>
  </Wrapper>
);
