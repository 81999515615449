import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';
import { MainScreen8Card } from './main-screen-8-card';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 290rem;
  scroll-margin-top: 35vh;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 120rem;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Text1 = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 28rem;
  }
`;
const Text2 = styled.div`
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 87rem;
  text-align: center;
  width: 569rem;
  max-width: 100%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    margin-bottom: 61rem;
  }
`;

const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
`;

const CardsWraper = styled.div`
  display: flex;
  gap: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
    gap: 0;
    row-gap: 30rem;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const MainScreen8: React.FC = () => (
  <Wrapper id={'loc1'}>
    <ContainerLocal>
      <Text1>
        Пользователям
      </Text1>
      <Divider/>
      <Text2>
        Вы можете приобрести сертификат
        на обслуживание или установку техники
        на специальных условиях
      </Text2>
      <CardsWraper>
        <MainScreen8Card
          text={'Сертификаты на обслуживание'}
          href={'https://service.mytech.global/?openFrom=mainLanding'}
        />
        <MainScreen8Card
          text={'Сертификаты на установку'}
          href={'https://installation.mytech.global/?openFrom=mainLanding'}
        />
      </CardsWraper>
    </ContainerLocal>
  </Wrapper>
);
