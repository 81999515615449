import React from 'react';
import styled from 'styled-components';
import { LinksToMobileApps, mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';

export type MainScreen7Props = {};

const Wrapper = styled.div`
  margin-bottom: 226rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 70rem;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  align-items: center;
  background: url('/static/screen-7/bg2.svg') center center / cover no-repeat, linear-gradient(92.21deg, #7851CF -11.03%, #808EFF 106.35%);
  border-radius: 50rem;
  padding: 61rem 80rem 0 175rem;
  max-width: calc(1000rem + 96rem + 62rem);
  box-sizing: border-box;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
    border-radius: 0;
    max-width: 200%;
    width: calc(100%);
    padding: 36rem 16rem 0;
    box-sizing: border-box;
  }
  
`;
const SideImage = styled.div`
  flex-shrink: 0;
  margin-right: 80rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    order: 1;
    margin-right: 0;
  }

  & > img {
    flex-shrink: 0;
    display: block;
    width: 261rem;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      margin-top: 73rem;
      margin-left: auto;
      margin-right: auto;

    }
  }
`;
const SideText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    align-items: center;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 44rem;
  line-height: 130%;
  text-align: right;
  color: #FFFFFF;
  margin-bottom: 24rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
    margin-bottom: 24rem;

  }
`;
const Caption = styled.div`
  font-weight: 300;
  font-size: 28rem;
  line-height: 140%;
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 63rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    margin-bottom: 61rem;
    text-align: center;
  }
`;

export const MainScreen7: React.FC<MainScreen7Props> = () => (
  <Wrapper>
    <ContainerLocal>
      <SideImage>
        <img
          src={'/static/screen-7/phone.png'}
          alt={'Телефон с приложением майтек'}
        />
      </SideImage>
      <SideText>
        <Title>
          Мы ценим ваше время
        </Title>
        <Caption>
          Скачивайте мобильное приложение.
          МайТех - это решение любых вопросов
          с техникой в формате одного окна.
        </Caption>
        <LinksToMobileApps/>
      </SideText>
    </ContainerLocal>
  </Wrapper>
);
