import { Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Color, ProcessingErrorIcon, ProcessingSuccessIcon } from '~/ui';

interface MainProcessingProps {
  isSuccess: boolean;
  errorText?: string;
  title: string;
  subtitleText: string;
  units?: string;
}

const Link = styled.a`
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  color: #8280EB
`;
const Icon = styled.div<{ units: string; }>`
  svg {
    width: 109${(props) => props.units};
    height: 109${(props) => props.units};
  }
`;

export const MainProcessing: React.FC<MainProcessingProps> = (props) => {
  const units = props.units ?? 'px';

  if (props.isSuccess) {
    return (
      <Stack
        spacing={units === 'rem' ? 5 * 8 + units : 5}
        alignItems={'center'}
      >
        <Icon units={units}>
          <ProcessingSuccessIcon/>
        </Icon>
        <Stack spacing={units === 'rem' ? 5 * 8 + units : 5}>
          <Typography
            sx={{
              fontSize: [
                `24${units}`,
                `24${units}`,
                `24${units}`,
                `24${units}`,
                `24${units}`,
              ],
            }}
            textAlign={'center'}
          >
            {props.title}
          </Typography>
          <Stack spacing={units === 'rem' ? 2.5 * 8 + units : 2.5}>
            <Typography
              sx={{
                fontSize: [
                  `18${units}`,
                  `18${units}`,
                  `20${units}`,
                  `20${units}`,
                  `20${units}`,
                ],
                color: Color.Gray3,
              }}
              textAlign={'center'}
            >
              {props.subtitleText}
            </Typography>
            <Typography
              sx={{
                fontSize: [
                  `18${units}`,
                  `18${units}`,
                  `20${units}`,
                  `20${units}`,
                  `20${units}`,
                ],
                color: Color.Gray3,
              }}
              textAlign={'center'}
            >
              Остались вопросы? Свяжитесь с нами
              по телефону 8 (800) 770-71-08
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={units === 'rem' ? 5 * 8 + units : 5}
      alignItems={'center'}
    >
      <Icon units={units}>
        <ProcessingErrorIcon/>
      </Icon>
      <Stack spacing={units === 'rem' ? 5 * 8 + units : 5}>
        <Typography
          sx={{
            fontSize: [
              `24${units}`,
              `24${units}`,
              `24${units}`,
              `24${units}`,
              `24${units}`,
            ],
          }}
          textAlign={'center'}
        >
          {props?.errorText}
        </Typography>
        <Stack spacing={units === 'rem' ? 2.5 * 8 + units : 2.5}>
          <Typography
            sx={{
              fontSize: [
                `18${units}`,
                `18${units}`,
                `20${units}`,
                `20${units}`,
                `20${units}`,
              ],
              color: Color.Gray3,
            }}
            textAlign={'center'}
          >
            Попробуйте вернуться
            на <Link href={'/'}>Главную страницу</Link>
            и отправить форму заново
          </Typography>
          <Typography
            sx={{
              fontSize: [
                `18${units}`,
                `18${units}`,
                `20${units}`,
                `20${units}`,
                `20${units}`,
              ],
              color: Color.Gray3,
            }}
            textAlign={'center'}
          >
            Остались вопросы? Свяжитесь с нами
            по телефону 8 (800) 770-71-08
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
