import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';

export type MainListProps = {
  items: Array<string>;
};

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 32rem;
  width: 100%;
  max-width: 540rem;
  padding-left: 0;
  margin: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    gap: 25rem;
  }
`;
const Item = styled.li`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 20rem;
  font-weight: 300;
  font-size: 20rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 16rem;
    line-height: 140%;
  }
`;
const CircleWithCheckIcon = styled.p`
  width: 48rem;
  height: 48rem;
  background: #FFFFFF;
  box-shadow: 0 2px 12px rgba(120, 81, 207, 0.1), inset 0 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 40rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: 38rem;
    height: 38rem;
  }
  img {
    width: 19.56rem;
    height: 15.03rem;
  }
`;

export const MainList: React.FC<MainListProps> = (props) => (
  <List>
    {
      props.items.map((el, idx) => (
        <Item key={idx}>
          <CircleWithCheckIcon>
            <img src={'/static/icon-check.svg'} alt={'Иконка галочка'}/>
          </CircleWithCheckIcon>
          <span>
            {el}
          </span>
        </Item>
      ))
    }
  </List>
);
