import React from 'react';
import styled from 'styled-components';
import {
  IconCase,
  IconInfo,
  IconWashingMachine,
  IconWebcam,
  mediaBreakpoints,
} from '~/ui';
import { MainContainer } from './main-container';

const Wrapper = styled.div`
  margin-bottom: 139rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-bottom: 120rem;
    margin-bottom: 0;
    overflow: hidden;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    align-items: flex-start;
    margin-bottom: 32rem;
  }
`;
const Left = styled.div`
  display: grid;
  grid-template-columns: 108rem 108rem;
  gap: 25rem;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    order: 1;
    display: flex;
    padding-left: 80rem;
    gap: 12rem;
  }
`;

const SquareItem = styled.div<{ dark?: boolean; showBorder?: boolean; }>`
  width: 108rem;
  height: 108rem;
  color: ${(props) => (props.dark ? 'white' : '#8280EB')};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-shrink: 0;
    width: 60rem;
    height: 60rem;
  }

  svg {
    width: 53rem;
    height: 53rem;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      width: 25rem;
      height: 25rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 18rem;
    box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
    background: ${(props) => (props.dark ? '#8280EB' : 'white')};
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      border-radius: 10rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 8rem;
    left: 9rem;
    z-index: -2;
    border: 3rem solid #DCDCFF;
    border-radius: 24rem;
    display: ${(props) => props.showBorder ? 'block' : 'none'};
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      border-radius: 10rem;
      border: 1.5rem solid #DCDCFF;
      top: 4rem;
      left: 4rem;
    }
  }
`;

const Title = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  text-align: right;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }
`;
const Caption = styled.div`
  max-width: 612rem;
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  text-align: right;
  color: rgba(27, 32, 64, 0.7);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
  }
`;

const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 20rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;

export const MainScreen2: React.FC = () => (
  <Wrapper>
    <ContainerLocal>
      <Left>
        <SquareItem showBorder>
          <IconCase/>
        </SquareItem>
        <SquareItem>
          <IconWebcam/>
        </SquareItem>
        <SquareItem dark>
          <IconWashingMachine/>
        </SquareItem>
        <SquareItem>
          <IconInfo/>
        </SquareItem>
      </Left>
      <Right>
        <Title>
          МайТех - станет для вас незаменимым помощником
        </Title>
        <Divider/>
        <Caption>
          Мы создаём условия, которые превратят установку, ремонт и уход за
          бытовой техникой и электроникой в лёгкую задачу
        </Caption>
      </Right>
    </ContainerLocal>
  </Wrapper>
)
