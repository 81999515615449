import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';
import { MainScreen4Card } from './main-screen-4-card';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1440rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 77rem;
  padding-bottom: 328rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-bottom: 265rem;
  }
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    align-items: flex-start;
  }
`;

const Text1 = styled.div`
  max-width: 687rem;
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }

`;
const Text2 = styled.div`
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 90rem;
  text-align: center;
  width: 743rem;
  max-width: 100%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
    margin-bottom: 22rem;
  }

`;

const Divider = styled.div`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 20rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;

const CardsWraper = styled.div`
  display: flex;
  gap: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Position1 = styled.div`
  position: absolute;
  bottom: 251rem;
  right: 0;
  transform: translateX(40%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    bottom: 123rem;
  }

  img {
    width: 606rem;
  }
`;

const Position2 = styled.div`
  position: absolute;
  top: 0;
  left: 200rem;
  transform: translateX(40%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }
`;
const Position3 = styled.div`
  position: absolute;
  right: 260rem;
  bottom: 70rem;
  transform: translateX(40%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }
`;

export const MainScreen4: React.FC = () => (
  <Wrapper>
    <Position1>
      <img
        src={'/static/screen-4/man-on-the-cicrcle.svg'}
        alt={'Человек на шаре с телефоном'}
      />
    </Position1>
    <Position2>
      <img
        src={'/static/screen-4/icon-circle.svg'}
        alt={'Кружочек'}
      />
    </Position2>
    <Position3>
      <img
        src={'/static/screen-4/icon-square.svg'}
        alt={'Квадратик'}
      />
    </Position3>
    <ContainerLocal>
      <Text1>
        Наш опыт всегда с вами
      </Text1>
      <Divider/>
      <Text2>
        Если вы не уверены в необходимости ремонта или диагностики, сомневаетесь
        в корректной настройке или выборе расходников, то мы поможем решить
        любой ваш вопрос в режиме онлайн
      </Text2>
      <CardsWraper>
        <MainScreen4Card
          icon={<img src={'/static/screen-4/icon-webcam.svg'}
                     alt={'Иконва вебкамеры'}/>}
          title={'Видеоконсультация'}
          caption={'от 399 ₽'}
        />
        <MainScreen4Card
          icon={<img src={'/static/screen-4/icon-dialog.svg'}
                     alt={'Иконка диалога'}/>}
          title={'Чат со специалистом'}
          caption={'Бесплатно'}
        />
      </CardsWraper>
    </ContainerLocal>
  </Wrapper>
);
