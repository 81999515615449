import React from 'react';
import styled from 'styled-components';
import { Color, LinksToMobileApps, mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';

export type MainScreen1Props = {
  id?: string;
};

const Wrapper = styled.div`
  max-width: 1440rem;
  width: 100%;
  position: relative;
  margin-bottom: 227rem;
  margin-left: auto;
  margin-right: auto;
  scroll-margin-top:0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-bottom: 0;
    margin-bottom: 111rem;
  }
`;

const LocalContainer = styled(MainContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 192rem;
  padding-bottom: 470rem;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding-top: 92rem;
    padding-bottom: 0;
  }
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 52rem;
  line-height: calc(52 / 54);
  margin: 0 0 51rem;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
    text-align: center;
    font-weight: 700;
    font-size: 32rem;
    line-height: 145%;
    margin-bottom: 39rem;
  }

  b {
    font-weight: 400;
    font-size: 38rem;
    color: ${Color.Gray2};
    padding-left: 15rem;
    padding-top: 8rem;
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      font-weight: 400;
      font-size: 24rem;
      line-height: 34rem;
      padding: 0;
    }
  }
`;
const Squares = styled.img`
  position: absolute;
  top: 149rem;
  left: 0;
  transform: translateX(-50%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    transform: translateX(-50%) scale(0.5);
    top: 41rem;
  }
`;
const Square = styled.img`
  position: absolute;
  top: 149rem;
  left: 50%;
  transform: translateX(250%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

`;
const Circle = styled.img`
  position: absolute;
  top: 249rem;
  right: 109rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

`;
const Position1 = styled.div`
  position: absolute;
  top: 407rem;
  left: -26rem;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

  img {
    width: 236rem;
  }
`;
const Position2 = styled.div`
  position: absolute;
  top: 469rem;
  left: 50%;
  transform: translateX(-70%);
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

  img {
    width: 406rem;
  }
`;
const Position3 = styled.div`
  position: absolute;
  top: 393rem;
  right: -39rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

  img {
    width: 382rem;
  }
`;
const MobileImage = styled.img`
  width: calc(100% + 32rem);
  margin-left: 0;
  margin-top: 36rem;
  display: block;
  max-width: none;

  @media screen and (min-width: ${mediaBreakpoints.desktopToMobile - 1}px) {
    display: none;
  }
`;

export const MainScreen1: React.VFC<MainScreen1Props> = (props) => (
  <Wrapper id={props.id}>
    <Squares
      src={'/static/screen-1/icon-squares.svg'}
    />
    <Square
      src={'/static/screen-1/icon-square.svg'}
    />
    <Circle
      src={'/static/screen-1/icon-circle.svg'}
    />
    <LocalContainer>
      <Title>
        МайТех - <b>Ваш эксперт по технике</b>
      </Title>
      <LinksToMobileApps/>
      <Position1>
        <img
          src={'/static/screen-1/illustration-1.png'}
          alt={'Рабочий'}
        />
      </Position1>
      <Position2>
        <img
          src={'/static/screen-1/illustration-2.svg'}
          alt={'Мужик с огромным телефоном'}
        />
      </Position2>
      <Position3>
        <img
          src={'/static/screen-1/illustration-3.svg'}
          alt={'Мужик в расписании'}
        />
      </Position3>
      <MobileImage src={'/static/screen-1/mobile-illustration.png'}/>
    </LocalContainer>
  </Wrapper>
);
