import React from 'react';
import styled from "styled-components";
import {mediaBreakpoints} from "~/ui";
import './page-offer.css'

const Wrapper = styled.div`
  background-color: #F6F6FB;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    padding: 50px 0;
    ul {
      padding-left: 10px;
    }
  }
`;

export const PageOfferWorkers = () => (
    <Wrapper>
        <div className="offer-doc">

            <h1 className="text-center">ОФЕРТА ПОДРЯДЧИКАМ</h1>
            <p className="text-small"><i>Версия от 01 марта 2023</i></p>
            <p>Настоящий документ является предложением Общества с ограниченной ответственностью «Техника Вашего дома»
                заключать договоры на выполнение работ по диагностике и ремонту бытовой, телевизионной, иной техники и
                цифровых устройств.</p>

            <h2 className="text-center">Термины и определения</h2>
            <p>В целях настоящей оферты нижеприведенные термины используются в следующих значениях:</p>
            <p><b>Аккредитация Подрядчика</b> — процесс допуска Подрядчика к функционалу Платформы, позволяющий
                Подрядчику принимать Заказы Клиентов и заключающийся в прохождении установленного Заказчиком
                тестирования навыков и квалификации Подрядчика.</p>
            <p><b>Акцепт Оферты</b> — полное и безоговорочное принятие Оферты Подрядчиком путем прохождения Аккредитации
                на Платформе и подтверждения принятия Заказа Клиента в работу. Акцепт Оферты означает заключение
                Договора.</p>
            <p><b>Данные статистики</b> — данные систем автоматизированного учета информации Заказчика, которые могут
                содержать сведения для расчета стоимости работ и иные сведения, относящиеся к процессу выполнения работ,
                в электронном виде.</p>
            <p><b>Договор</b> — возмездный договор между Подрядчиком и Заказчиком на выполнение работ по диагностике и
                ремонту Техники Клиента, который заключается посредством Акцепта Оферты.</p>
            <p><b>Заказ Клиента</b> — выраженное посредством Платформы обращение Клиента к Заказчику с целью диагностики
                или ремонта Техники.</p>
            <p><b>Заказчик</b> — Общество с ограниченной ответственностью «Техника Вашего дома», ОГРН 1215000106133, ИНН
                5047257258, адрес места нахождения - 141402, Россия, Московская Обл., г. Химки, ул. Репина, д. 2/27,
                этаж 9, офис 903.</p>
            <p><b>Запрос Клиента</b> — доступная Подрядчику (кругу Подрядчиков, подходящих по условиям выполнения работ)
                посредством Платформы информация о спросе Клиента на выполнение работ по диагностике и ремонту бытовой
                техники.</p>
            <p><b>Клиент</b> — конечный потребитель (получатель) работ по диагностике и ремонту бытовой, телевизионной,
                иной техники и цифровых устройств, выполняемых Подрядчиком. Клиент надлежаще уполномочен давать
                Подрядчику указания по объему работ, их приемке и подтверждению факта выполнения Подрядчиком Заказа
                Клиента.</p>
            <p><b>Оферта</b> — текст настоящего документа, который постоянно доступен по ссылке.</p>
            <p><b>Платформа</b> — веб-сайт <a href="https://mytech.global/">https://mytech.global/</a> или мобильные
                приложения MyTech, предназначенные для получения информации о различных работах и/или услугах, а также
                для размещения и принятия Заказов Клиентов Заказчиком, заключения Договоров, выполнения работ по
                диагностике и ремонту Техники Клиентов, осуществления взаимодействия и проведения взаиморасчетов между
                участниками договорных отношений.</p>
            <p><b>Подрядчик</b> — физическое лицо в статусе индивидуального предпринимателя или самозанятого или
                юридическое лицо, прошедшее Аккредитацию на Платформе, непосредственно выполняющее для Заказчика работы
                по диагностике и ремонту Техники Клиента. В случае утраты физическим лицом статуса индивидуального
                предпринимателя или самозанятого Договор с Подрядчиком прекращается автоматически.</p>
            <p><b>Тарифы</b> — стоимость работ Подрядчика по диагностике и ремонту Техники в отдельном Заказе,
                определенные исходя из стоимости работ, указанной в прайс-листе для Подрядчика.</p>
            <p><b>Техника</b> — бытовая, телевизионная техника и цифровые устройства, в том числе оборудование,
                необходимое для обеспечения их работы.</p>


            <h2>1. Предмет договора</h2>
            <div className="pad">
                <p>1.1. Подрядчик обязуются по полученному от Заказчика Запросу Клиента выполнить для Клиента работу на
                    условиях Договора, а Заказчик обязуется принять и оплатить такие работы Подрядчика.</p>
                <p>1.2. Подробный перечень работ, которые Подрядчик должен выполнить для Клиента, состав таких работ и
                    сроки выполнения указаны в Заказе Клиента, размещенном через Платформу. Стоимость работ Подрядчика
                    определяется исходя из объема работ в Заказе Клиента на основании Тарифов. Указанный Заказ,
                    размещенный через Платформу, после принятия Подрядчиком в работу становится неотъемлемой частью
                    настоящего Договора.</p>
                <p>1.3. Подрядчик выполняет работы по месту нахождения Клиента, либо в ином указанном в Заказе
                    месте.</p>
            </div>


            <p><b>2. Обязанности и права</b></p>
            <div className="pad">
                <p>2.1. Заказчик обязан:</p>
                <div className="pad">
                    <p>2.1.1. Обеспечить Подрядчику доступ к Платформе (в том числе к Заказам Клиентов). При этом
                        Заказчик не несет ответственности в случае невозможности доступа Подрядчика к Платформе по
                        причинам, не зависящим от Заказчика.</p>
                    <p>2.1.2. Принимать и оплатить работы Подрядчика, выполненные надлежащим образом и в полном
                        объеме.</p>
                </div>
            </div>

            <div className="pad">
                <p>2.2. Подрядчик обязан:</p>
                <div className="pad">
                    <p>2.2.1. Выполнять для Заказчика работы по диагностике и ремонту Техники в соответствии с
                        критериями, указанными Клиентом в Заказе, а также в полном соответствии с требованиями
                        законодательства РФ.</p>
                    <p>2.2.2. Качественно выполнить работы и оказать услуги, предусмотренные настоящим Договором, в
                        согласованный срок.</p>
                    <p>2.2.3. Организовать работы по диагностике и ремонту с соблюдением норм безопасности и требований
                        по охране труда. В ходе ремонта использовать качественные детали, изделия, материалы, оснащение
                        и инструменты.</p>
                    <p>2.2.4. Обеспечить сохранность Техники на период ремонта вне расположения Заказчика и/или
                        Клиента.</p>
                    <p>2.2.5. Обеспечить сохранность запасных частей, деталей и комплектующих для ремонта Техники,
                        полученных от Заказчика и/или Клиента.</p>
                    <p>2.2.6. Точно следовать инструкциям и указаниям Заказчика.</p>
                    <p>2.2.7. Предоставить Заказчику контактные данные лиц, ответственных за оперативное взаимодействие
                        по вопросам исполнения Договора.</p>
                    <p>2.2.8. Нести ответственность за сохранность и конфиденциальность регистрационных данных (логин и
                        пароль). Все действия, осуществленные с использованием логина и пароля Подрядчика на Платформе,
                        считаются осуществленными Подрядчиком. Подрядчик самостоятельно несет ответственность перед
                        третьими лицами за все действия, совершенные с использованием логина и пароля Подрядчика.
                        Заказчик не несет ответственности за несанкционированное использование регистрационных данных
                        Подрядчика третьими лицами.</p>
                    <p>2.2.9. Не предоставлять персональные данные, включая телефонные номера Клиентов, третьим лицам,
                        и/или не использовать персональные данные Клиентов для целей, не предусмотренных Договором, в
                        том числе не осуществлять звонки, рассылку сообщений, содержание которых прямо не относится к
                        Заказам Клиентов в рамках Платформы (спам, реклама и прочее).</p>
                    <p>2.2.10. Незамедлительно уведомить Заказчика об изменении применяемой Подрядчиком системы
                        налогообложения.</p>
                </div>
            </div>

            <div className="pad">
                <p>2.3. Заказчик вправе:</p>
                <div className="pad">
                    <p>2.3.1. В безусловном порядке изменять (дополнять) условия оферты и требований к порядку
                        выполнения работ.</p>
                    <p>2.3.2. Предоставить запасные части и материалы, необходимые для выполнения Заказа.</p>
                    <p>2.3.3. По собственному усмотрению изменять Тарифы и условия выполнения работ.</p>
                    <p>2.3.4. Отказать Подрядчику в заключении Договора, аккредитации и допуске к Платформе при
                        выявлении несоответствия предоставленной Подрядчиком информации (включая, но не ограничиваясь,
                        информацией в отношении опыта, навыков и квалификации Подрядчика) фактической.</p>
                    <p>2.3.5. Осуществить действия по ограничению доступа Подрядчика к функционалу Платформы (в том
                        числе к исполнению Заказов Клиентов) при нарушении условий настоящего Договора.</p>
                    <p>2.3.6. Требовать от Подрядчика выполнения его обязательств по Договору в срок и с надлежащим
                        качеством.</p>
                    <p>2.3.7. В любое время проверять ход и качество работы, выполняемой Подрядчиком.</p>
                    <p>2.3.8. В случае неисполнения или ненадлежащего исполнения Подрядчиком принятых на себя
                        обязательств по настоящему Договору Заказчик вправе удерживать денежные средства, подлежащие
                        перечислению Заказчику, в счет возмещения причиненных Заказчику убытков.</p>
                </div>
            </div>

            <div className="pad">
                <p>2.4. Подрядчик вправе:</p>
                <div className="pad">
                    <p>2.4.1. Требовать от Заказчика своевременной и полной оплаты надлежащим образом выполненных и
                        принятых Заказчиком работ в соответствии с настоящим Договором.</p>
                </div>
            </div>

            <p><b>3. Стоимость услуг и порядок оплаты</b></p>
            <div className="pad">
                <p>3.1. Стоимость работ Подрядчика (включая применимые налоги) рассчитывается на основании Данных
                    статистики, доступных Подрядчику на Платформе и в соответствии с Тарифами. Тарифы, для целей
                    настоящего Договора, признаются указанными с учетом НДС.</p>
                <p>3.2. Стороны признают, что в целях расчета стоимости работ Подрядчика за выполненные работы
                    используются исключительно Данные статистики.</p>
                <p>3.3. Заказчик оплачивает выполненные работы Подрядчика в срок, не превышающий десяти рабочих дней с
                    момента, когда Заказчик получил оплату за соответствующую работу от Клиента</p>
                <p>3.4. В течение десяти рабочих дней с момента исполнения каждого отдельного Заказа Клиента Подрядчик
                    на основании Данных статистики формирует акт сдачи-приемки выполненных работ с указанием стоимости
                    выполненной работы Подрядчика, подписывает, заверяет печатью (при наличии) и направляет акт
                    Заказчику по почте с одновременным направлением копии акта по электронной почте. Стороны признают,
                    что копия акта, направленная Подрядчиком Заказчику по электронной почте, будет применяться Сторонами
                    в процессе приемки работ до момента получения оригинала акта. В случае если Заказчику был направлен
                    акт, подписанный квалифицированной электронной подписью, посредством электронного документооборота,
                    отправка акта по электронной почте не осуществляется. Датой получения акта, направленного Заказчиком
                    Подрядчику по электронной почте, считается следующий рабочий день за днем отправки.</p>
                <p>3.5. Стоимость работ Подрядчика определяется на основании Тарифов, исходя из Заказа клиента.</p>
            </div>

            <p><b>4. Ответственность сторон</b></p>
            <div className="pad">
                <p>4.1. За неисполнение или ненадлежащее исполнение условий настоящего Договора Стороны несут
                    ответственность в соответствии с законодательством РФ.</p>
                <p>4.2. В случае ненадлежащего исполнения Стороной своих обязательств по Договору, другая Сторона имеет
                    право в одностороннем порядке отказаться от исполнения Договора, направив другой Стороне
                    соответствующее письменное уведомление.</p>
                <p>4.3. Подрядчик несет ответственность в полном объеме за соблюдение всех требований законодательства
                    РФ, в том числе, законодательства о защите прав потребителей, при выполнении работ.</p>
                <p>4.4. Подрядчик обязуется возместить в полном объеме убытки (включая судебные расходы), причиненные
                    Заказчику в связи с претензиями и исками, основанием предъявления которых явилось выполнение работ
                    Подрядчиком. В случае если выполнение работ явилось основанием для предъявления к Заказчику
                    предписаний по уплате штрафных санкций со стороны государственных органов, или претензий со стороны
                    третьих лиц, Подрядчик обязуется незамедлительно по требованию Заказчика предоставить ему всю
                    запрашиваемую информацию, содействовать Заказчику в урегулировании требований/претензий, а также
                    возместить в полном объёме все убытки (включая расходы по уплате штрафов), причиненные Заказчику
                    вследствие предъявления ему требований/претензий в результате выполнения работ.</p>
                <p>4.5. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по
                    Договору, если оно явилось следствием обстоятельств непреодолимой силы. Наступление обстоятельств
                    непреодолимой силы подтверждается документами, выдаваемыми компетентными органами РФ. Сторона, у
                    которой возникла невозможность исполнения обязательств по Договору, обязана немедленно известить
                    другую сторону о наступлении и прекращении вышеуказанных обстоятельств.</p>
            </div>

            <p><b>5. Гарантийные обязательства</b></p>
            <div className="pad">
                <p>5.1. Срок гарантийных обязательств на качество выполненных работ устанавливается в каждом случае
                    индивидуально в соответствии с требованиями и рекомендациями производителей.</p>
            </div>

            <p><b>6. Срок действия, изменение и расторжение Договора</b></p>
            <div className="pad">
                <p>6.1. Договор вступает в силу с момента Акцепта Оферты Подрядчиком и действует до момента расторжения
                    Договора.</p>
                <p>6.2. Договор может быть расторгнут:</p>
                <div className="pad">
                    <p>6.2.1. В любое время по соглашению Сторон.</p>
                    <p>6.2.2. По инициативе любой из Сторон в одностороннем порядке с обязательным предварительным
                        письменным уведомлением другой Стороны не менее чем за 15 календарных дней до даты
                        расторжения.</p>
                    <p>6.2.3. По инициативе любой из Сторон в случае нарушения другой Стороной условий Договора с
                        уведомлением другой Стороны.</p>
                    <p>6.2.4. По иным основаниям, предусмотренным настоящей Офертой и/или законодательством РФ.</p>
                </div>
                <p>6.3. Расторжение договора не освобождает Стороны от ответственности за нарушения условий Договора,
                    возникшие в течение срока его действия.</p>
            </div>

            <p><b>7. Разрешение споров</b></p>
            <div className="pad">
                <p>7.1. Все споры по Договору должны разрешаться с соблюдением предварительного претензионного
                    порядка.</p>
                <p>7.2. Если Стороны не могут прийти к соглашению в течение 30 (Тридцати) дней с момента получения одной
                    из Сторон первой претензии, то все не урегулированные в таком порядке споры и разногласия Сторон
                    подлежат рассмотрению в Арбитражном суде г. Москвы в соответствии с законодательством РФ.</p>
            </div>

            <p><b>8. Заключительные положения</b></p>
            <div className="pad">
                <p>8.1. Заказчик оставляет за собой право вносить изменения в Оферту. Указанные изменения вступают в
                    силу по истечении 1 (одного) календарного дня с даты опубликования.</p>
                <p>8.2. Стороны признают, что документы, оформленные средствами электронной связи (включая электронную
                    почту), а также посредством действий с использованием Платформы, имеют равную юридическую силу с
                    документами на бумажных носителях, подписанными собственноручно подписями уполномоченных лиц и
                    заверенными оттиском печати (если применимо).</p>
                <p>8.3. Заявления, уведомления, извещения, требования или иные юридически значимые сообщения, с которыми
                    закон или сделка связывает наступление гражданско-правовых последствий для другого лица, влекут для
                    этого лица такие последствия с момента доставки соответствующего сообщения ему или его
                    представителю. Сообщение считается доставленным и в тех случаях, если оно поступило лицу, которому
                    оно направлено (адресату), но по обстоятельствам, зависящим от него, не было ему вручено или адресат
                    не ознакомился с ним.</p>
                <p>8.4. В случае если одно или более положений Договора будут признаны недействительными, такая
                    недействительность не оказывает влияния на действительность любого другого положения Договора и
                    Договора в целом.</p>
                <p>8.5. Не вступая в противоречие с условиями Оферты, Заказчик и Подрядчик вправе в любое время оформить
                    Договор в форме письменного, двухстороннего документа.</p>
                <p>8.6. Информация, содержащаяся в Договоре, имеет конфиденциальный характер. За исключением случаев,
                    прямо указанных в Договоре или предварительно согласованных Сторонами в письменной форме в виде
                    дополнительного соглашения к Договору, ни одна из Сторон не вправе каким-либо образом использовать
                    полное, сокращенное фирменное наименование, товарные знаки, коммерческие обозначения и иные средства
                    индивидуализации, принадлежащие второй Стороне, без предварительного разрешения последней.</p>
            </div>

            <p><b>9. Реквизиты Заказчика</b></p>

            <p>Общество с ограниченной ответственностью «Техника вашего дома»</p>
            <p>
                Место нахождения: 141402, Московская область, Городской округ Химки, г. Химки, ул. Репина, д. 2/27, этаж
                9, офис 903<br/>
                ОГРН 1215000106133<br/>
                ИНН 5047257258<br/>
                КПП 504701001<br/>
                Р/счет: 40702810710001192177в АО «Тинькофф Банк»<br/>
                К/счет: 30101810145250000974<br/>
                БИК: 044525974<br/>
                Генеральный директор Каменский С.А.
            </p>


        </div>
    </Wrapper>
);
