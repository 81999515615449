import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

  }, [pathname]);

  return null;
};
