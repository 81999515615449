import { Checkbox, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  IconCheckboxChecked,
  IconCheckboxNotChecked,
  mediaBreakpoints,
} from '~/ui';
import { useMainService } from '../di';
import { MainContainer } from './main-container';
import { MainList } from './main-list';
import { MainProcessing } from './main-processing';

const Wrapper = styled('div')`
  margin-bottom: 107rem;
  scroll-margin-top: 35vh;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 0;
  }
`;

const Text1 = styled('div')`
  font-style: normal;
  font-weight: 600;
  font-size: 44rem;
  line-height: 125%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    text-align: left;
    font-weight: 600;
    font-size: 28rem;
    line-height: 125%;
  }
`;

const Text2 = styled('div')`
  font-weight: 300;
  font-size: 24rem;
  line-height: 140%;
  color: rgba(27, 32, 64, 0.7);
  margin-bottom: 90rem;
  text-align: center;
  width: 865rem;
  max-width: 100%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    color: rgba(27, 32, 64, 0.7);
    text-align: left;
    margin-bottom: 40rem;
  }
`;
const Divider = styled('div')`
  width: 100rem;
  height: 6rem;
  background: #8280EB;
  border-radius: 6rem;
  margin-top: 32rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 20rem;
    margin-bottom: 20rem;
    width: 60rem;
    height: 6rem;
    border-radius: 6rem;
  }
`;

const ContainerLocal = styled(MainContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Inner = styled('div')`
  display: flex;
  gap: 70rem;
  justify-content: space-between;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: flex;
    flex-direction: column;
    gap: 50rem;
  }
`;
const Left = styled('div')``;
const Right = styled('div')`
  width: 424rem;
  min-height: 100rem;
  background: #FFFFFF;
  box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
  border-radius: 20rem;
  flex-shrink: 0;
  padding: 20rem 32rem;
  box-sizing: border-box;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: calc(100% + 32rem);
    margin-left: -16rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 32rem 16rem;
  }
`;
const Caption = styled('div')`
  margin-top: 105rem;
  font-weight: 300;
  font-size: 20rem;
  line-height: 140%;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-top: 77rem;
    text-align: center;
  }
`;
const Title = styled('div')`
  font-weight: 500;
  font-size: 28rem;
  line-height: 40rem;
  margin-bottom: 30rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 28rem;
    line-height: 130%;
    margin-bottom: 24rem;
  }
`;
const TextFieldWrapper = styled('div')`
  width: 100%;
  margin-bottom: 24rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 8rem;
  }

  & > * {
    width: 100%;

    .MuiInputBase-root:not(.MuiInputBase-multiline) {
      border-radius: 20rem;
      height: 64rem;

      input {
        font-size: 16rem;
        padding: 16.5rem 14rem 16.5rem 24rem;
        outline: none;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #BCBFD1;
      border-width: 1px !important;
      font-size: 16rem !important;
      legend {
        font-size: 16px !important;
      }
    }
    .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline{
      border: 1px solid #838AAF !important;
    }

    .MuiInputBase-multiline {
      padding: 16.5rem 14rem 16.5rem 24rem;
      border-radius: 20rem;

      textarea {
        font-size: 16rem;
        line-height: 1;
        height: auto;

      }
    }
    .MuiInputLabel-root {
      font-size: 16rem;
      left: 7px;
      top: 3px;
      color: #BCBFD1;
      &.Mui-focused:not(.Mui-error) {
        color: #838AAF;
      }
    }

    .MuiFormHelperText-root {
      font-size: 14rem;
    }
  }
`;

const Submit = styled('button')`
  font-weight: 500;
  font-size: 18rem;
  border-radius: 16rem;
  line-height: 125%;
  color: #F5F7FC;
  width: 100%;
  height: 60rem;
  background: #1B2040;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;

  &:hover {
    background: #090E2B;
  }

  &:active {
    background: #060A21;
  }
  &:focus {
    border: 1px solid #EF5DA8;
  }
`;

const Rights = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12rem;
  margin-bottom: 30rem;

  p {
    font-weight: 500;
    font-size: 14rem;
    line-height: 125%;
    margin: 0;
  }

  .MuiSvgIcon-root {
    font-size: 40rem;
    padding: 0;
  }

  .MuiCheckbox-root {
    padding: 0;
  }

  .error {
    color: #d32f2f;
    font-size: 14rem;
    display: flex;
    width: 100%;
  }
`;

export const MainScreen11: React.FC = () => {
  const mainService = useMainService();
  const [sendResult, setSendResult] = useState<string | null | void>(void 0);

  const formik = useFormik<{
    name: string;
    email: string;
    message: string;
    agreement: boolean;
  }>({
    initialValues: {
      name: '',
      email: '',
      message: '',
      agreement: true,
    },
    validationSchema: Yup.object()
      .shape({
        name: Yup.string()
          .required('Заполните поле'),
        email: Yup.string()
          .email('E-mail заполнен неверно')
          .required('Заполните поле'),
        message: Yup.string()
          .required('Заполните поле'),
        agreement: Yup.bool().oneOf([true], 'Вы должны принять согласие на обработку данных!'),
      }),
    onSubmit: async (data) => {
      try {
        await mainService.sendForVendorsAndDistributors(data);
        setSendResult(null);
      } catch (err) {
        setSendResult(err.message);
      }
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Wrapper id={'loc3'}>
      <ContainerLocal>
        <Text1>
          Для вендоров и дистрибьюторов
        </Text1>
        <Divider/>
        <Text2>
          Для вендоров и дистрибьюторов МайТех реализует связь с конечным
          потребителем для повышения лояльности к бренду
        </Text2>
        <Inner>
          <Left>
            <MainList
              items={[
                'Мы знаем у кого какая техника и срок её жизни',
                'Помогаем с правильной установкой и корректной эксплуатацией',
                'Сообщаем как обслуживать технику и правильно проводить ремонт',
                'Предлагаем инструменты для обучения и авторизации мастеров под особенности конкретного бренда и модели',
              ]}
            />
            <Caption>
              Свяжитесь с нами и мы предложим комплекс решений для
              взаимовыгодного сотрудничества
            </Caption>
          </Left>
          <Right>
            {
              sendResult === null
              && (
                <div style={{ padding: '40rem 0' }}>
                  <MainProcessing
                    units={'rem'}
                    isSuccess
                    title={'Заявка отправлена'}
                    subtitleText={'Наш менеджер скоро свяжется с вами для уточнения деталей.'}
                  />
                </div>
              )
            }
            {
              sendResult
              && (
                <div style={{ padding: '40rem 0' }}>
                  <MainProcessing
                    units={'rem'}
                    isSuccess={false}
                    title={''}
                    subtitleText={''}
                    errorText={'Ошибка отправки'}
                  />
                </div>

              )
            }
            {
              (typeof sendResult === 'undefined') && (
                <>
                  <Title>Оформление заявки</Title>
                  <TextFieldWrapper>
                    <TextField
                      placeholder={'Имя'}
                      label={'Имя'}
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('name')}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <TextField
                      placeholder={'E-mail'}
                      label={'E-mail'}
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('email')}
                      error={Boolean(formik.touched.email && formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper>
                    <TextField
                      placeholder={'Сообщение'}
                      label={'Сообщение'}
                      multiline
                      rows={5}
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('message')}
                      error={Boolean(formik.touched.message && formik.errors.message)}
                      helperText={formik.touched.message && formik.errors.message}
                    />
                  </TextFieldWrapper>
                  <Rights>
                    <Checkbox
                      icon={<IconCheckboxNotChecked/>}
                      checkedIcon={<IconCheckboxChecked/>}
                      defaultChecked
                      required
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...formik.getFieldProps('agreement')}
                    />
                    <p>
                      <span>
                        Согласен на обработку &nbsp;
                      </span>
                      <a
                        href={'/about/personal'}
                        target={'_blank'}
                        rel={'noreferrer'}
                        style={{ color: '#8280EB' }}
                      >
                        персональных данных
                      </a>
                    </p>
                    {
                      Boolean(formik.touched.agreement && formik.errors.agreement) && (
                        <p className={'error'}>
                          {
                            formik.errors.agreement
                          }
                        </p>
                      )
                    }
                  </Rights>
                  <Submit onClick={handleSubmit}>
                    Отправить заявку
                  </Submit>
                </>
              )
            }
          </Right>
        </Inner>
      </ContainerLocal>
    </Wrapper>
  );
};
