import React from 'react';
import styled from 'styled-components';
import { LinksToMobileApps, mediaBreakpoints } from '~/ui';
import { MainContainer } from './main-container';

export type MainScreen10Props = {};

const Wrapper = styled.div`
  margin-bottom: 209rem;
  position: relative;
  width: 100%;
  max-width: 1440rem;
  margin-left: auto;
  margin-right: auto;
`;
const ContainerLocal = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Icon = styled.div`
  overflow: hidden;
  box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
  border-radius: 24rem;
  margin-bottom: 32rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    margin-bottom: 50rem;
  }

  img {
    width: 87rem;
  }
`;
const Title = styled.div`
  width: 100%;
  max-width: 680rem;
  font-weight: 300;
  font-size: 28rem;
  line-height: 140%;
  margin-bottom: 36rem;
  text-align: center;

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 400;
    font-size: 18rem;
    line-height: 140%;
    margin-bottom: 57rem;
  }
`;
const Position1 = styled.div`
  position: absolute;
  top: -190rem;
  left: -129rem;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    display: none;
  }

  img {
    width: 311rem;
  }
`;

export const MainScreen10: React.FC<MainScreen10Props> = () => {

  return (
    <Wrapper>
      <Position1>
        <img
          src={'/static/screen-10/rabochii.svg'}
          alt={'Мужик в рабочем костюме'}
        />
      </Position1>
      <ContainerLocal>
        <Icon>
          <img
            src={'/static/screen-10/icon-rosette.jpg'}
            alt={'Иконка розетки'}
          />
        </Icon>
        <Title>
          Скачивайте приложение МайТех для мастеров.
          Мы предлагаем вместе развивать платформу
          и повышать качество сервиса
        </Title>
        <LinksToMobileApps
          appleLink={'https://apps.apple.com/ru/app/майтех-про-для-мастеров/id1610340703'}
          googleLink={'https://play.google.com/store/apps/details?id=online.u3.mytech.master'}
        />
      </ContainerLocal>
    </Wrapper>
  );
}
