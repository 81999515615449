import React from 'react';
import styled from 'styled-components';
import { mediaBreakpoints } from '~/ui';

export type MainScreen4CardProps = {
  text: string;
  href: string;
};

const Wrapper = styled.a`
  text-decoration: none;
  padding: 27rem 30rem 26rem 24rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 14rem 30rem rgba(122, 112, 234, 0.15);
  border-radius: 24rem;
  display: flex;
  gap: 30rem;
  width: 383rem;
  color: #fff;
  height: 146rem;
  background: url('/static/screen-8/card-bg.svg') center center / cover no-repeat, linear-gradient(-92.21deg, #7851CF -11.03%, #808EFF 106.35%);

  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: 100%;
    height: 104rem;
    padding: 0 14rem 0 24rem;
    align-items: center;
  }
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 24rem;
  line-height: 140%;
  margin-bottom: 4rem;
  display: flex;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    font-weight: 500;
    font-size: 18rem;
    line-height: 140%;
    margin: 0
  }
`;
const Button = styled.div`
  width: 40rem;
  height: 40rem;
  background: #F5F7FC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-shrink: 0;
  align-self: flex-end;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    align-self: center;
  }

  & > img {
    width: 12rem;
    height: 12rem;
  }
`;

export const MainScreen8Card: React.FC<MainScreen4CardProps> = (props) => (
  <Wrapper href={props.href}>
    <Title>
      {props.text}
    </Title>
    <Button>
      <img
        src={'/static/screen-8/icon-arrow-to-right.svg'}
        alt={'Иконка стрелка направо'}
      />
    </Button>
  </Wrapper>
);
